import React from "react";
import { Link } from "gatsby";
import SEO from "../Components/seo";
import * as Theme from "../Theme";

const NotFoundPage = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: Theme.colors.blueGreen,
      padding: 20,
      height: "70vh",
    }}
  >
    <SEO title="404: Not found" />
    <figure
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src="../../images/404.svg"
        width="280px"
        style={{ maxWidth: 650, marginBottom: 30 }}
      />
      <p style={{ fontSize: 16, marginBottom: 5, color: "#ffffff" }}>
        您所搜尋的頁面已不存在或已被移除！
      </p>
      <Link
        to="/"
        style={{
          cursor: "pointer",
          marginTop: 50,
          padding: "10px 50px",
          border: "2px solid #ffffff",
          borderRadius: 3,
          color: "#fff",
        }}
      >
        回到感官首頁
      </Link>
    </figure>
  </div>
);

export default NotFoundPage;
